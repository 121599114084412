:root {
  --color-alpha: #f2f2f2;
  --color-beta: #151515;
  --border-radius: 4px;

  /*
  --color-alpha: #000022;
  --color-beta: #ffffff;
  */
}

body {
  background-color: #000;
}

.App {
  display: flex;
  background-color: var(--color-beta); /* #FF5F1F */
  gap: 0px;
  position: relative;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

.canvas-view {
  position: fixed !important;
  top: 0px;
  left: 0px;
  margin: auto;
  width: 100vw !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  z-index: 100;
  display: block;
}

img, video {
  border-radius: var(--border-radius);
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-beta);
  transform-origin: top;
  z-index: 99999;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-beta);
  transform-origin: bottom;
  z-index: 99999;
}
