@font-face {
  font-family: "Besier";
  src: url("./fonts/basiersquare-regular-webfont.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Besier";
  src: url("./fonts/basiersquare-semibold-webfont.woff2") format("woff2");
  font-weight: bold;
}

html {
  font-size: 12px;
}

body {
  margin: 0;
  font-family: 'Neue Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-alpha);
}

*::-webkit-scrollbar {
  display: none;
}


.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
   pointer-events: none;
} 


.mouse-container {
  pointer-events: none;
  transform-origin: center;
  z-index: 999999;
  width: 1px;
  height: 1px;
  border-radius: 100;
  position: fixed;
  transform: translate(0, 0);
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse-pointer {
  pointer-events: none;
  transform-origin: center;
  border-radius: 100px;
  color: var(--color-beta);
  background: var(--color-alpha);
  position: absolute;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  background-blend-mode: difference;
  mix-blend-mode: difference;
}
